<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title>
          Override
        </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            v-model="username"
            outlined
            dense
            label="Username"
            placeholder="john"
            :hide-details="formErrorFields.usernameErrorMessage.length === 0"
            :class="formErrorFields.usernameErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.usernameErrorMessage"
            @keydown="formError().remove('username', formErrorFields)"
            class="mb-3"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            outlined
            dense
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
            :class="formErrorFields.passwordErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.passwordErrorMessage"
            @keydown="formError().remove('password', formErrorFields)"
            @keypress.enter="confirm"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="cancel"
          >
            Nevermind
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="confirm"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import crud from '@/composables/crud'
import formError from '@/composables/formErrorHandler/formError'

export default {
  props: {
    show: Boolean,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const dialog = ref(false)
    const isPasswordVisible = ref(false)
    const password = ref('')
    const username = ref('')
    const formErrorFields = ref({
      usernameErrorMessage: '',
      passwordErrorMessage: '',
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const confirm = async () => {
      await store(`${process.env.VUE_APP_URI}/api/cashiering/account/override`, loading, { 
        password: password.value,
        username: username.value
      }, formErrorFields)
      if (responseMessageStatus.value === 'success') {
        password.value = ''
        emit('execute')
        emit('cancel')
      }
    }

    const cancel = () => {
      emit('cancel')
    }

    watch(() => props.show, show => {
      dialog.value = show
    })

    return {
      username,
      password,
      isPasswordVisible,
      formErrorFields,
      formError,
      confirm,
      cancel,
      responseMessage,
      responseMessageStatus,
      dialog,
      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
